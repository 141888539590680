<template>
  <BasicLayout class="is-small">
    <template v-slot:content>
      <h1 class="title">
        {{ $t('login.recover') }}
      </h1>

      <b-message v-if="isInstitutionalEmail" type="is-danger">
        A recuperação de password de contas institucionais deverá ser feita através da
        <a href="https://apps.uc.pt/recover" target="_blank"> área privada </a>.
      </b-message>

      <b-message v-if="recoverSent" has-icon type="is-success">
        Foi enviada uma mensagem para a sua caixa de correio, com um código para recuperação da sua conta.<br />
        <small>Caso tenha dificuldades em encontrar a mensagem, por favor, procure na sua caixa de SPAM.</small>
      </b-message>

      <b-message v-if="errors.length" type="is-danger">
        <div v-for="error in errors" :key="error.key">
          <div v-if="error.key == 'EmailAlreadyExistsOrNotConfirmed'">
            O email não existe ou não se encontra confirmado.
          </div>
          <div v-else>
            <p>Não foi possível realizar a recuperação de password.</p>
            <p>{{ error.message }}</p>
          </div>
        </div>
      </b-message>

      <form
        v-if="!recoverSent"
        class="login-form"
        method="post"
        @keypress.enter="doRecover"
        @submit.prevent="doRecover"
      >
        <transition name="fade">
          <b-field
            label="Email"
            label-for="email"
            :type="{ 'is-danger': $v.email.$error }"
            :message="$v.email.$error ? $t('invalidEmail') : ''"
          >
            <b-input v-model="email" type="email"></b-input>
          </b-field>
        </transition>

        <b-field>
          <b-button expanded type="submit" class="is-primary" outlined @click="doRecover">
            {{ $t('continue') }}
          </b-button>
        </b-field>
      </form>

      <p class="instructions">
        Para conta institucionais uc.pt, por favor, tente recuperar a sua password através da
        <a href="https://apps.uc.pt/recover" target="_blank"> área privada</a>.
      </p>

      <div>
        <router-link to="/login" class="has-text-weight-bold">{{ $t('login.open') }}</router-link>
      </div>
    </template>
  </BasicLayout>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'

import BasicLayout from '@/components/layouts/BasicLayout'
import AuthService from '@/services/AuthService'

export default {
  components: {
    BasicLayout,
  },

  data() {
    return {
      email: '',
      errors: [],
      recoverSent: false,
    }
  },

  validations: {
    email: { required, email, min: minLength(5) },
  },

  computed: {
    isInstitutionalEmail() {
      return AuthService.helpers.isInstitutionalEmail(this.email)
    },
  },

  methods: {
    doRecover() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        AuthService.recover(this.email)
          .then(() => {
            this.recoverSent = true
          })
          .catch(err => {
            this.errors = err.response.data.__errors__
          })
      }
    },
  },
}
</script>
